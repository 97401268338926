<div class="block lg:flex justify-between items-center mb-3 lg:mb-0">
  <el-tabs v-model="userType" @tab-click="changeUser">
    <el-tab-pane
      v-if="indexRole < 1"
      label="ITax General"
      name="ITaxGeneral"
      class="w-full"
    />
    <el-tab-pane
      v-if="indexRole < 2"
      label="Sale Admin"
      name="SaleAdmin"
      class="w-full"
    />
    <el-tab-pane
      v-if="indexRole < 2 || indexRole == 6"
      label="Company Super Admin"
      name="CompanySuperAdmin"
      class="w-full"
    />
    <el-tab-pane
      v-if="indexRole < 3 || indexRole == 6"
      label="Company Super Admin Sub"
      name="CompanySuperAdminSub"
      class="w-full"
    />
    <el-tab-pane
      v-if="indexRole < 4 || indexRole == 6"
      label="Company Admin"
      name="CompanyAdmin"
      class="w-full"
    />
    <el-tab-pane
      v-if="indexRole < 5 || indexRole == 6"
      label="Company User"
      name="CompanyUser"
      class="w-full company-user-style"
    />
    <el-tab-pane
      v-if="indexRole == 4"
      label="userNone"
      name="userNone"
      class="w-full"
    />
  </el-tabs>

  <div class="text-right itax-button">
    <el-button
      size="mini"
      type="primary"
      icon="el-icon-plus"
      @click="openAddUser()"
    >
      Thêm người dùng
    </el-button>
  </div>
</div>

<!-- Add User below -->
<el-dialog
  v-model="dialogAddUser"
  title="Thêm người dùng"
  :width="dialogWidth"
  @closed="resetValidate()"
>
  <Form
    as="el-form"
    :validation-schema="addUserModal"
    ref="reset"
    @submit="confirmAddUser()"
    :label-position="dialogWidth == '50%' ? 'left':'top'"
  >
    <el-form-item class="w-full" label="Vai trò" label-width="155px">
      <el-select
        v-model="addUser.user_type"
        style="width: 100%"
        placeholder="Chọn user type"
      >
        <el-option
          v-for="item in listRoleUser"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      class="w-full relative"
      :class="isEmail ? 'border-error': ''"
      label="Email"
      label-width="155px"
    >
      <InputWithValidation
        label="Email"
        v-model="addUser.emailQuanLyTaiKhoan"
        name="emailQuanLyTaiKhoan"
      />
      <span v-if="isEmail" class="absolute text-xs text-red-400">
        {{ errorEmail }}
      </span>
    </el-form-item>
    <el-form-item
      v-if="addUser.user_type == 'CompanyAdmin' || addUser.user_type == 'CompanyUser' || addUser.user_type == 'CompanySuperAdminSub'"
      class="w-full"
      label="Công ty"
      label-width="155px"
      :error="errorCompany"
    >
      <el-select
        v-if="addUser.user_type == 'CompanySuperAdminSub'"
        v-model="addUser.company_id"
        style="width: 100%"
        placeholder="Chọn công ty"
        filterable
        multiple
        remote
        :remote-method="remoteMethod"
      >
        <el-option
          v-for="item in companiesSelect"
          :key="item.id"
          :label="item.tenDoanhNghiep"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-select
        v-else
        v-model="addUser.company_id"
        style="width: 100%"
        placeholder="Chọn công ty"
        remote
        :remote-method="remoteMethod"
        filterable
        name="company"
      >
        <el-option
          v-for="item in companiesSelect"
          :key="item.id"
          :label="item.tenDoanhNghiep"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <div class="dialog-footer text-right itax-button">
      <el-button size="mini" @click="dialogAddUser = false">Hủy</el-button>
      <el-button size="mini" type="primary" native-type="submit">
        Xác nhận
      </el-button>
    </div>
  </Form>
</el-dialog>

<el-scrollbar class="height-table" v-loading="isLoading">
  <el-table
    :data="users"
    border
    stripe
    style="width: 100%; word-break: break-word"
    empty-text="Không có dữ liệu"
    :header-cell-style="{ background: 'white' }"
    @row-click="clickRow($event)"
  >
      <el-table-column
        type="index"
        label="STT"
        :index="indexMethod"
        width="50"
        align="center"
      />
      <el-table-column prop="user.hoTen" label="Họ tên">
        <template #default="props">
          <div class="flex justify-between items-center">
            <p>{{props.row.user.hoTen}}&nbsp;</p>
            <UserIcon :user="props.row.user"/>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-if="userType == 'CompanySuperAdmin'"
        prop="plan"
        label="Gói thành viên"
        width="150"
        align="center"
      />

      <el-table-column
        prop="user.emailQuanLyTaiKhoan"
        label="Email quản lý tài khoản"
        width="300"
      />
      <el-table-column
        v-if="userType == 'CompanyAdmin' || userType == 'CompanyUser'"
        prop="companies[0].tenDoanhNghiep"
        label="Tên công ty"
        width="300"
      />
      <el-table-column
        v-else
        prop="user.soDienThoai"
        label="Số điện thoại"
        width="150"
        align="center"
      />
      <el-table-column
        prop="user.expired_at"
        label="Ngày tạo"
        align="center"
        width="150"
      >
        <template #default="props">
          {{ formatDate(props.row.user.created_at) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="user.expired_at"
        label="Ngày hết hạn"
        align="center"
        width="150"
      >
        <template #default="props">
          {{ props.row.user.expired_at ? formatDate(props.row.user.expired_at) : 'Chưa kích hoạt' }}
        </template>
      </el-table-column>
      <el-table-column label="" align="center" width="100">
        <template #header> Thao tác </template>
        <template #default="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="openEditModal(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
  </el-table>
</el-scrollbar>

<div>
  <paginate
    :currentPage="paginate.currentPage"
    :totalItem="paginate.totalItem"
    :totalPage="paginate.totalPage"
    :limit="paginate.limit"
    @modifiedCurrentPage="handleCurrentPageChange($event)"
    @update:limit="handleLimitUpdate"
  />
</div>

<el-dialog
  v-model="showEditModal"
  :title="userType == 'ITaxGeneral' ? 'Chỉnh sửa người dùng': ''"
  @closed="resetValidate()"
  v-if="addUser.id"
  :width="dialogWidth"
  top="9vh"
>
  <el-tabs v-model="tabDetailUser" v-if="userType != 'ITaxGeneral'">
    <el-tab-pane label="Chỉnh sửa" name="EditUser" class="w-full" />
    <el-tab-pane label="Công ty" name="Company" class="w-full" />
    <el-tab-pane v-if="indexRole == 3" label="userNone" name="userNone" class="w-full" />
    <el-tab-pane v-if="getUserRole == 'ITaxAdmin'" label="Lịch sử" name="userLogging" class="w-full" />
  </el-tabs>

  <!-- Edit below -->
  <Form
    v-if="tabDetailUser == 'EditUser'"
    class="mt-3"
    as="el-form"
    :validation-schema="editUserModal"
    ref="reset"
    @submit="confirmUpdateUser()"
    :label-position="dialogWidth == '50%' ? 'left':'top'"
  >
    <el-form-item
      class="w-full relative"
      :class="isEmail ? 'border-error': ''"
      label="Email"
      label-width="155px"
    >
      <InputWithValidation
        label="Email"
        v-model="addUser.user.emailQuanLyTaiKhoan"
        name="emailQuanLyTaiKhoan"
      />
      <span v-if="isEmail" class="absolute text-xs text-red-400">
        {{ errorEmail }}
      </span>
    </el-form-item>
    <el-form-item class="w-full" label="Họ tên" label-width="155px">
      <InputWithValidation
        label="Họ tên"
        v-model="addUser.user.hoTen"
        name="hoTen"
      />
    </el-form-item>
    <el-form-item class="w-full" label="Số điện thoại" label-width="155px">
      <InputWithValidation
        label="Số điện thoại"
        v-model="addUser.user.soDienThoai"
        name="soDienThoai"
      />
    </el-form-item>
    <el-form-item class="w-full" label="Mật khẩu" label-width="155px">
      <InputWithValidation
        type="password"
        v-model="updateUser.password"
        label="Mật khẩu"
        name="matKhau"
      />
    </el-form-item>
    <el-form-item class="w-full" label="Xác nhận mật khẩu" label-width="155px">
      <InputWithValidation
        type="password"
        v-model="updateUser.password_confirm"
        label="Xác nhận mật khẩu"
        name="xacNhanMatKhau"
      />
    </el-form-item>
    <el-form-item
      v-if="userType == 'CompanySuperAdminSub' || userType == 'CompanyAdmin' || userType == 'CompanyUser' && indexRole < 4"
      class="w-full"
      label="Công ty"
      label-width="155px"
      :error="errorCompanyEdit"
    >
      <el-select
        v-if="userType == 'CompanySuperAdminSub'"
        v-model="addUser.companies_ids"
        style="width: 100%"
        placeholder="Chọn công ty"
        filterable
        multiple
        remote
        :remote-method="remoteMethod"
      >
        <el-option
          v-for="item in companiesSelect"
          :key="item.id"
          :label="item.tenDoanhNghiep"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-select
        v-else
        v-model="addUser.companies[0].id"
        style="width: 100%"
        placeholder="Chọn công ty"
        filterable
        remote
        :remote-method="remoteMethod"
      >
        <el-option
          v-for="item in companiesSelect"
          :key="item.id"
          :label="item.tenDoanhNghiep"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item class="w-full" label="Ngày hết hạn" label-width="155px">
      <el-date-picker
        v-model="addUser.user.expired_at"
        style="width: 100%"
        type="date"
        placeholder="Chọn ngày hết hạn"
        format="DD/MM/YYYY"
      />
    </el-form-item>
    <el-form-item
      class="w-full"
      v-if="userType == 'CompanySuperAdminSub' || userType == 'CompanyAdmin' || userType == 'CompanyUser' "
      label="Vai trò"
      label-width="155px"
    >
      <el-select
        v-model="addUser.user.userType"
        style="width: 100%"
        placeholder="Chọn user type"
        :disabled="getUserIndexRole <= 2"
      >
        <el-option
          v-if="userType == 'CompanySuperAdmin' || userType == 'CompanySuperAdminSub'"
          label="CompanySuperAdmin"
          value="CompanySuperAdmin"
        />
        <el-option
          v-if="userType == 'CompanySuperAdmin' || userType == 'CompanySuperAdminSub'"
          label="CompanySuperAdminSub"
          value="CompanySuperAdminSub"
        />
        <el-option
          v-if="userType == 'CompanyAdmin' || userType == 'CompanyUser'"
          label="CompanyAdmin"
          value="CompanyAdmin"
        />
        <el-option
          v-if="userType == 'CompanyAdmin' || userType == 'CompanyUser'"
          label="CompanyUser"
          value="CompanyUser"
        />
      </el-select>
    </el-form-item>

    <el-form-item class="w-full" label="SL Hóa đơn giới hạn" label-width="155px" v-if="userType == 'CompanySuperAdmin'">
      <InputWithValidation
        v-model="addUser.invoice_gov_max"
        label="Hóa đơn đã đọc"
        name="invoice_gov_max"
        type="number"
      />
    </el-form-item>

    <el-form-item class="w-full" label="Hóa đơn đã đọc" label-width="155px" v-if="userType == 'CompanySuperAdmin'">
      <InputWithValidation
        v-model="addUser.invoice_gov_count"
        label="Hóa đơn đã đọc"
        name="invoice_gov_count"
        disabled
      />
    </el-form-item>

    <el-form-item class="w-full" label="Gói thành viên" label-width="155px" v-if="userType == 'CompanySuperAdmin'">
      <el-select
        v-model="addUser.plan"
        style="width: 100%"
      >
        <el-option
          v-for="item in ['basic', 'premium']"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <div class="dialog-footer text-right itax-button mt-3">
      <el-button size="mini" @click="showEditModal = false"> Hủy </el-button>
      <el-button
        v-if="tabDetailUser == 'EditUser'"
        size="mini"
        type="primary"
        native-type="submit"
      >
        Xác nhận
      </el-button>
    </div>
  </Form>

  <div v-if="tabDetailUser == 'Company'" >
    <el-scrollbar class="height-table-expand">
      <el-table
        :data="addUser.companies"
        row-key="id"
        border
        ref="tableData"
        style="width: 100%"
        @row-click="clickRowExpanCompany"
      >
        <el-table-column type="index" label="STT" width="50" align="center" />
        <el-table-column
          prop="tenDoanhNghiep"
          label="Tên công ty"
          min-width="350"
        />
        <el-table-column
          prop="chuSoHuu"
          label="Đại diện pháp luật"
          min-width="150"
        />
        <el-table-column prop="mst" label="Mã số thuế" min-width="100" />
        <el-table-column type="expand">
          <template #default="props">
            <el-table
              :data="props.row.company_user"
              border
              style="width: calc(100% - 50px); float: right"
            >
              <el-table-column
                type="index"
                label="STT"
                width="50"
                align="center"
              />
              <el-table-column
                prop="emailQuanLyTaiKhoan"
                label="Email quản lý tài khoản"
              />
              <el-table-column prop="userType" label="Vai trò" width="200" />
              <el-table-column
                prop="expired_at"
                label="Ngày hết hạn"
                align="center"
                width="120"
              >
                <template #default="props">
                  <div>{{formatDate(props.row.expired_at)}}</div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
  </div>

  <div v-if="tabDetailUser == 'userLogging'" >
    <div class="flex justify-between">
      <div>
      Thời gian
      <el-date-picker
        v-model="userLoggingPaginate.date"
        type="daterange"
        start-placeholder="Ngày bắt đầu"
        end-placeholder="Ngày kết thúc"
        :shortcuts="shortcuts"
        :disabled-date="disabledDate"
      />
      </div>
      <div class="items-center flex">
        <el-button class="items-center" size="mini" type="primary" @click="getUserLogging">Tìm kiếm</el-button>
      </div>
    </div>
    <el-scrollbar class="height-table-expand mt-2" v-loading="isUserLoggingLoading">
      <el-table
        :data="addUser.userLogging"
        row-key="id"
        border
        stripe
        ref="tableData"
        class="user-logging-table"
      >
        <el-table-column type="index" label="STT" width="50" align="center" :index="indexMethodUserLogging" />
        <el-table-column label="Thời gian bắt đầu phiên">
          <template #default="props">
            <div>{{formatDate(props.row.created_at)}} - {{formatTime(props.row.created_at)}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Thời gian kết thúc phiên">
          <template #default="props">
            <div>{{formatDate(props.row.updated_at)}} - {{formatTime(props.row.updated_at)}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Khoảng thời gian">
          <template #default="props">
            <div>{{durationReadAble(props.row.created_at, props.row.updated_at)}}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <paginate
      :currentPage="userLoggingPaginate.currentPage"
      :totalItem="userLoggingPaginate.totalItem"
      :totalPage="userLoggingPaginate.totalPage"
      :limit="userLoggingPaginate.limit"
      @modifiedCurrentPage="handleUserLoggingCurrentPageChange($event)"
      @update:limit="handleUserLoggingLimitUpdate"
    />
  </div>

  <div
    v-if="tabDetailUser != 'EditUser'"
    class="dialog-footer text-right itax-button mt-3"
  >
    <el-button size="mini" @click="showEditModal = false"> Đóng </el-button>
  </div>
</el-dialog>
