<template>
  <div>
    <el-input
      id="input-search"
      v-model="displayValue"
      :size="size"
      :placeholder="placeholder"
      clearable
      @keyup="keyUpSearch()"
      @clear="keyUpSearch()"
    >
      <template #append v-if="isIcon" class="p-0">
        <el-button
          icon="el-icon-search"
          size="mini"
          @click="keyUpSearch()"
        ></el-button>
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Tìm kiếm...",
    },
    size: {
      type: String,
      default: "small",
    },
  },

  data() {
    return {
      typingTimer: null,
    };
  },

  methods: {
    keyUpSearch() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.doneTyping();
      }, 500);
    },

    keyDownSearch() {
      clearTimeout(this.typingTimer);
    },

    doneTyping() {
      this.$emit("doneTyping");
    },

    focusInput() {
      const input = document.getElementById("input-search");
      input.focus();
      input.select();
    },
  },

  computed: {
    displayValue: {
      get: function () {
        return this.modelValue;
      },

      set: function (modifiedValue) {
        this.$emit("inputSearch", modifiedValue);
      },
    },
  },
};
</script>

<style scoped>
::v-deep(.el-input-group__append) {
  padding: 0 1rem
}
</style>
