import { URL } from "@/api/users";
import { URL as CompanyURL } from "@/api/company";
import { Form } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";
import InputWithValidation from "@/components/inputWidthvalidate.vue";
import _, { isNumber } from "lodash";
import { ref } from "vue";
import formatDate from "@/plugins/formatDate.js";
import formatTime from "@/plugins/formatTime.js";
import searchInput from "@/components/searchInput";
import paginate from "@/components/paginate.vue";
import UserIcon from "@/components/UserIcon.vue";
import { durationReadAble } from "../../../plugins/common";

export default {
  components: {
    Form, InputWithValidation,
    searchInput,
    paginate,
    UserIcon,
  },
  data() {
    return {
      activeUser: "CompanyUser",
      users: [],
      addUser: {
        userLogging: {}
      },
      shortcuts: [
        {
          text: "1 tuần trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "2 tuần trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
            return [start, end];
          },
        },
        {
          text: "1 tháng trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
      ],
      dialogAddUser: false,
      showEditModal: false,
      search: "",
      userType: null,
      tabDetailUser: "EditUser",
      user_type: [
        "ITaxAdmin",
        "ITaxGeneral",
        "CompanySuperAdmin",
        "CompanySuperAdminSub",
        "CompanyAdmin",
        "CompanyUser",
        "SaleAdmin", // T.T fu** smell code. Sorry...
      ],
      indexRole: 0,
      listRoleUser: [],
      updateUser: {
        password: "",
        password_confirm: "",
      },
      listCompanyUser: [],
      dialogWidth: "50%",
      isEmail: false,
      errorEmail: null,
      isUserName: false,
      errorUserName: null,
      paginate: {
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      userLoggingPaginate: {
        date: [null, null],
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      errorCompany: "",
      errorCompanyEdit: "",
      emailUser: null,
      isConfirmChangeEmail: false,
      listUser: [],
      isLoading: false,
      isUserLoggingLoading: false,
      companies: [],
      companiesSelect: [],
    };
  },

  created() {
    this.search = this.getUserSearch;
    this.indexRole = this.user_type.indexOf(this.getUserRole);
    this.userType = this.user_type[this.indexRole + 1];
    if (this.indexRole == 6) {
      // SaleAdmin
      this.userType = "CompanySuperAdmin"
    }
    this.getListUser();
    this.getListRoleUser();
    this.setDialogWidth();
    this.remoteMethod();
  },

  setup() {
    const editUserModal = yup.object({
      hoTen: yup.string().max(100, "Tối đa 100 ký tự").nullable(),
      soDienThoai: yup
        .string()
        .matches(/^\d*$/, {
          message: "Số điện thoại không đúng định dạng",
        })
        .max(11, "Số điện thoại tối đa 11 ký tự")
        .nullable(),
      emailQuanLyTaiKhoan: yup
        .string()
        .email("Email không đúng định dạng")
        .required("Email là một trường bắt buộc"),
      matKhau: yup
        .string()
        .min(8, "Mật khẩu ít nhất 8 ký tự")
        .nullable(true)
        .transform((v) => (v === "" ? null : v)),
      xacNhanMatKhau: yup
        .string()
        .nullable()
        .oneOf([yup.ref("matKhau")], "Mật khẩu không khớp")
        .transform((v) => (v === "" ? null : v)),
    });

    const addUserModal = yup.object({
      emailQuanLyTaiKhoan: yup
        .string()
        .required()
        .email("Email không đúng định dạng")
        .required("Email là một trường bắt buộc"),
    });
    const reset = ref(null);

    return {
      reset,
      editUserModal,
      addUserModal,
    };
  },

  methods: {
    ...mapActions(["loadingLayer"]),

    // Remote method
    async getListUser() {
      this.isLoading = true
      const params = {};
      params.page = this.paginate.currentPage;
      params.limit = this.paginate.limit;
      params.user_type = this.userType;
      if (this.search) {
        params.key_search = this.search;
      }

      const result = await this.$request({
        url: URL.LIST_USER,
        params,
      });
      const res = result.data;

      if (res.success && res.code == 200) {
        this.users = res.data.data;
        this.paginate.totalPage = res.data.total_pages;
        this.paginate.totalItem = res.data.total;
        this.paginate.limit = res.data.limit;
      }

      this.isLoading = false
    },

    async getListRoleUser() {
      const result = await this.$request({
        url: URL.ROLES_USER,
      });
      const res = result.data;

      if (res.success && res.code == 200) {
        this.listRoleUser = res.data.roles;
      }
    },

    async getUserLogging() {
      this.isUserLoggingLoading = true
      const params = {
        page: this.userLoggingPaginate.currentPage,
        limit: this.userLoggingPaginate.limit,
      }
      if (this.userLoggingPaginate.date && this.userLoggingPaginate.date[0]) {
        params['created_at__gte'] = this.userLoggingPaginate.date[0]

        let created_at__lte = new Date(this.userLoggingPaginate.date[1])
        created_at__lte.setTime(created_at__lte.getTime() + 86400 * 1000);
        params['created_at__lte'] = created_at__lte
      }
      const result = await this.$request({
        url: URL.GET_USER_LOGGING.replace(":id", this.addUser.user.id),
        params
      });
      const res = result.data;
      this.userLoggingPaginate.limit = res.data.limit;
      this.userLoggingPaginate.totalItem = res.data.total;
      this.userLoggingPaginate.totalPage = res.data.total_pages;
      this.addUser.userLogging = res.data.data;
      this.isUserLoggingLoading = false
    },


    async confirmAddUser() {
      const $isCompanySelected = this.addUser.company_id && !this.errorCompany;
      const $roleNotRequiredCompany = ["CompanySuperAdmin", "SaleAdmin"].indexOf(this.addUser.user_type) != -1;

      if ($isCompanySelected || $roleNotRequiredCompany) {
        this.loadingLayer(true);
        var data = _.cloneDeep(this.addUser);
        if (Number.isInteger(this.addUser.company_id)) {
          data.company_id = [this.addUser.company_id];
        }
        const result = await this.$request({
          url: URL.CREATE_USER,
          method: "POST",
          data,
        });
        const res = result.data;

        if (res.code == 200 && res.success) {
          this.$swal({
            text: "Tạo thành công",
            icon: "success",
            timer: "3000",
            width: "450",
            padding: "10px",
          });
          this.getListUser();
          this.dialogAddUser = false;
        }

        if (res.code == 400 && !res.success) {
          if (res.errors[0].email) {
            this.isEmail = true;
            this.errorEmail = res.errors[0].email;
          }
          if (res.errors[0].user_name) {
            this.isUserName = true;
            this.errorUserName = res.errors[0].user_name;
          }
          if (!this.errorEmail && !this.isUserName) {
            this.$swal({
              text: Object.values(res.errors[0]),
              icon: "error",
              timer: "3000",
              width: "450",
              padding: "10px",
            });
          }
        }
        this.loadingLayer(false);
      }
    },

    async confirmUpdate() {
      if (this.errorCompanyEdit == "" || ['ITaxGeneral', 'CompanySuperAdmin'].indexOf(this.userType) != -1) {
        if (this.isConfirmChangeEmail) {
          this.$swal({
            title: "Thông tin đã được thay đổi.",
            text: "Cập nhật người dùng",
            showCancelButton: true,
            confirmButtonText: "Xác nhận",
            cancelButtonText: "Hủy",
            confirmButtonColor: "#3b82f6",
            cancelButtonColor: "#adadad",
            reverseButtons: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.fetchcApiUpdate();
            }
          });
        } else {
          this.fetchcApiUpdate();
        }
      }
    },

    async fetchcApiUpdate() {
      this.loadingLayer(true);
      const data = {};
      data.emailQuanLyTaiKhoan = this.addUser.user.emailQuanLyTaiKhoan;
      data.soDienThoai = this.addUser.user.soDienThoai;
      data.hoTen = this.addUser.user.hoTen;
      data.user_name = this.addUser.user.user_name;

      if (this.userType == "CompanyAdmin" || this.userType == "CompanyUser") {
        try {
          data.company_id = [this.addUser.companies[0].id];
        } catch (error) {
          data.company_id = null;
        }
      } else if (this.userType == "CompanySuperAdminSub") {
        data.company_id = this.addUser.companies_ids;
      }

      if (this.userType == "CompanySuperAdmin") {
        data.invoice_gov_max = this.addUser.invoice_gov_max;
        data.plan = this.addUser.plan;
      }

      var d = new Date(this.addUser.user.expired_at);
      var datestring = `${d.getFullYear()}-
  ${("0" + (d.getMonth() + 1)).slice(-2)}-
  ${("0" + d.getDate()).slice(-2)}`;
      var date = datestring.replace(/\s+/g, "");

      data.expired_at = `${date} 23:59:59`;

      if (this.updateUser.password != "") {
        data.password = this.updateUser.password;
      }
      if (this.updateUser.password_confirm != "") {
        data.password_confirm = this.updateUser.password_confirm;
      }
      if (this.addUser.user.userType != this.userType) {
        data.new_role = this.addUser.user.userType;
      }
      const result = await this.$request({
        url: URL.UPDATE_USER.replace(":id", this.addUser.user.id),
        method: "PUT",
        data,
      });
      const res = result.data;
      if (res.code == 200 && res.success) {
        this.$swal({
          text: "Cập nhật thành công",
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
        this.getListUser();
        this.showEditModal = false;
      }
      if (!res.success && res.code == 400) {
        this.isEmail = true;
        this.errorEmail = res.errors[0].email;
        if (!this.errorEmail) {
          this.$swal({
            text: Object.values(res.errors[0]),
            icon: "error",
            timer: "3000",
            width: "450",
            padding: "10px",
          });
        }
      }

      this.loadingLayer(false);
    },

    // Search Company
    async remoteMethod(key_search="") {
      const result = await this.$request({
        url: CompanyURL.LIST_COMPANY,
        params: { key_search }
      });

      const res = result.data;

      if (res.code == 200) {
        this.companies = res.data.data
        const selectCompanies = this.addUser.companies || [];
        const selectCompaniesIds = selectCompanies.map(item => item['id'])

        this.companies = this.companies.filter(item => selectCompaniesIds.indexOf(item.id) == -1)
        this.companiesSelect = [...selectCompanies, ...this.companies]
      }
    },

    // Pagination + view
    confirmUpdateUser() {
      if (this.updateUser.password) {
        this.$swal({
          title: "Bạn có chắc không?",
          text: "Thay đổi mật khẩu người dùng!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3b82f6",
          cancelButtonColor: "#adadad",
          confirmButtonText: "Xác nhận",
          cancelButtonText: "Hủy!",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.confirmUpdate();
          }
        });
      } else this.confirmUpdate();
    },
    openAddUser() {
      this.dialogAddUser = true;
      this.addUser = {};
      this.addUser.user_type = this.userType;

      if (this.userType == "ITaxGeneral") {
        this.addUser.user_type = "CompanySuperAdmin";
      }
    },

    async openEditModal(data) {
      this.showEditModal = true;
      this.addUser = _.cloneDeep(data);

      if (this.getUserRole == "ITaxAdmin") {
        await this.getUserLogging();
      }
      this.companiesSelect = [...this.addUser.companies || [], ...this.companies]
      const users = ["CompanySuperAdminSub", "CompanyAdmin", "CompanyUser"];
      if (this.userType.indexOf(users) != -1) {
        if (!this.addUser.companies[0]) {
          this.addUser.companies[0] = {
            id: null,
          };
        }
      }
      this.emailUser = this.addUser.user.emailQuanLyTaiKhoan;
    },
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    clickRow(obj) {
      this.openEditModal(obj);
      if (this.userType == "CompanySuperAdminSub") {
        let company = [];
        this.addUser.companies.forEach((item) => {
          company = [...company, item.id];
        });
        this.addUser.companies_ids = company;
      }
      if (['ITaxAdmin', 'ITaxGeneral'].indexOf(this.userType) == -1){
        this.handleListUser(this.addUser);
      }
    },

    clickRowExpanCompany(row) {
      this.$refs.tableData.toggleRowExpansion(row);
    },

    handleListUser(obj) {
      obj.companies.forEach((item) => {
        let listCompanyUser = [];
        if (item.companySuperAdminDetail.id) {
          let companySuperAdmin = item.companySuperAdminDetail;
          listCompanyUser = [...listCompanyUser, companySuperAdmin.user];
        }

        let companySuperAdminSub = item.companySuperAdminSub;
        companySuperAdminSub.forEach((sub) => {
          listCompanyUser = [...listCompanyUser, sub.user];
        });

        let companyAdmin = item.companyAdmin;
        companyAdmin.forEach((admin) => {
          listCompanyUser = [...listCompanyUser, admin.user];
        });

        let companyUser = item.companyUser;
        companyUser.forEach(
          (user) => (listCompanyUser = [...listCompanyUser, user.user])
        );
        item.company_user = listCompanyUser;
      });
    },
    handleCurrentPageChange($event) {
      this.paginate.currentPage = $event;
      this.getListUser();
    },
    handleLimitUpdate(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.getListUser();
    },
    async handleUserLoggingCurrentPageChange($event) {
      this.userLoggingPaginate.currentPage = $event;
      this.getUserLogging();
    },
    async handleUserLoggingLimitUpdate(limit) {
      this.userLoggingPaginate.limit = limit;
      this.userLoggingPaginate.currentPage = 1;
      this.getUserLogging();
    },
    indexMethod(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },
    indexMethodUserLogging(index) {
      return index + 1 + (this.userLoggingPaginate.currentPage - 1) * this.userLoggingPaginate.limit;
    },
    searchInput() {
      this.paginate.totalPage = 1;
      this.getListUser();
    },
    changeUser() {
      this.paginate.totalPage = 1;
      this.paginate.currentPage = 1;
      this.getListUser();
    },
    focusInput() {
      const input = document.getElementById("input-search");
      input.focus();
      input.select();
    },
    resetValidate() {
      this.updateUser.password = "";
      this.updateUser.password_confirm = "";
      if (this.tabDetailUser == "EditUser") {
        this.reset.resetForm();
      }
      this.tabDetailUser = "EditUser";
    },
    setDialogWidth() {
      let windowSize = document.body.clientWidth;
      if (windowSize < 767) {
        this.dialogWidth = "90%";
      } else {
        this.dialogWidth = "50%";
      }
    },
    // Pagination + view end

    formatDate,
    formatTime,
    durationReadAble,
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },

  computed: {
    ...mapGetters([
      "companies",
      "selectCurrentCompany",
      "getUserSearch",
      "getUserIndexRole",
      "getUserRole",
    ]),

    changeEmailAdd() {
      if (this.addUser) {
        return this.addUser.emailQuanLyTaiKhoan;
      }
    },

    changeEmailUpdate() {
      if (this.addUser.user) {
        return this.addUser.user.emailQuanLyTaiKhoan;
      }
    },

    changeUserAdd() {
      return this.addUser.user_name;
    },

    companyId() {
      return this.addUser.company_id;
    },

    editCompanyId() {
      return this.addUser.companies;
    },

    addUserRole() {
      return this.addUser.user_type;
    },

    changeUserUpdate() {
      if (this.addUser.user) {
        return this.addUser.user.user_name;
      }
    },
  },

  watch: {
    selectCurrentCompany() {
      this.getListUser();
    },

    changeEmailAdd() {
      this.isEmail = false;
    },

    changeEmailUpdate(newVal) {
      this.isConfirmChangeEmail = true;
      if (this.emailUser === newVal) {
        this.isConfirmChangeEmail = false;
      }
      this.isEmail = false;
    },

    changeUserAdd() {
      this.isUserName = false;
    },

    changeUserUpdate() {
      this.isUserName = false;
      this.userLoggingPaginate.page = 1;
    },
    companyId(newVal) {
      this.errorCompany = "Công ty là một trường bắt buộc";
      if ((newVal && newVal.length) || isNumber(newVal)) {
        this.errorCompany = "";
      }
    },
    addUserRole() {
      this.addUser.company_id = "";
    },
    getUserSearch(newVal) {
      this.search = newVal
      this.searchInput();
    }
  },
};
