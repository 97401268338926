<template>
  <el-tooltip :content="user.emailQuanLyTaiKhoan || ''" class="user" effect="dark" placement="bottom">

    <span
      class="relative icon-email"
      :class="{'bg-super-admin' : user.userType == 'CompanySuperAdmin','bg-super-admin-sub' : user.userType == 'CompanySuperAdminSub','bg-admin' : user.userType == 'CompanyAdmin','bg-user' : user.userType == 'CompanyUser'}"
    >
      <div class="avatar w-full h-full" v-if="user.avatar_url">
        <img :src="user.avatar_url" alt="user_avatar" class="w-full h-full object-cover">
      </div>
      <div class="default-avatar" v-else> {{cutText(user.emailQuanLyTaiKhoan)}} </div>

      <div class="icon-group">
        <span v-if="user.userType == 'CompanySuperAdmin'" title="CompanySuperAdmin" class="icon-arrow">
          <img src="@/assets/icons/icon_single_up.svg" alt="" class="absolute h-5 w-5"/>
          <img src="@/assets/icons/icon_single_up.svg" alt="" class="absolute h-5 w-5"/>
          <img src="@/assets/icons/icon_single_up.svg" alt="" class="absolute h-5 w-5"/>
        </span>

        <span v-if="user.userType == 'CompanySuperAdminSub'" title="CompanySuperAdminSub" class="icon-arrow">
          <img src="@/assets/icons/icon_single_up.svg" alt="" class="absolute h-5 w-5"/>
          <img src="@/assets/icons/icon_single_up.svg" alt="" class="absolute h-5 w-5"/>
        </span>
        <span v-if="user.userType == 'CompanyAdmin'" title="CompanyAdmin" class="icon-arrow">
          <img src="@/assets/icons/icon_single_up.svg" alt="" class="absolute h-5 w-5"/>
        </span>
        <span v-if="user.is_invited" title="Tài khoản chưa được kích hoạt" class="icon-arrow-exclamation">
          <img src="@/assets/icons/icon_exclamation.svg" alt="" class="absolute h-5 w-5"/>
        </span>
        <span class="online-mark" v-if="isOnline(user.latest_request_at)"></span>
      </div>
    </span>

  </el-tooltip>
</template>
<script>
export default {
  props: {
    user: {},
  },

  data() {
    return {
      typingTimer: null,
    };
  },

  methods: {
    subtractMinutes (date,minutes) {
      return new Date(date - minutes * 60000);
    },
    isOnline(datetime) {
      return datetime && new Date(datetime) > this.subtractMinutes(new Date(), 1)
    },
    cutText(str) {
      return str.substr(0, 2).toUpperCase();
    },
  },

};
</script>

<style scoped>
.online-mark {
  background: var(--el-color-primary);
  width: 0.65rem;
  height: 0.65rem;
  position: absolute;
  left: 0.8rem;
  top: -0.25rem;
  border-radius: 50%;
  border: 2px solid #fff;
}
.avatar img {
  border-radius: 50%;
}
.icon-arrow img {
  right: -0.5rem;
}
.icon-arrow img:nth-child(1) {
  bottom: -4px;
}
.icon-arrow img:nth-child(2) {
  bottom: 1px;
}
.icon-arrow img:nth-child(3) {
  bottom: 6px;
}
.icon-arrow-exclamation img {
  top: -0.25rem;
  right: -0.45rem;
}
.bg-super-admin {
  background-color: #5075a8;
}
.bg-super-admin-sub {
  background-color: #5075a8;
}
.bg-admin {
  background-color: #5075a8;
}
.bg-user {
  background-color: #5075a8;
}
.border-Role {
  padding: 0 15px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: not-allowed;
  background: #f5f7fa;
  height: 40px;
}
.icon-email {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  min-width: 40px;
  width: 40px;
  height: 40px;
  font-size: 0.8rem;
  text-align: center;
  background-color: #5075a8;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  border: 2px solid #fff;
}
</style>

